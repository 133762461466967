<template>
  <v-container fluid>
    <CRUD id-method="params" :fields="fields" :table="table" :api="api">
      <template v-slot:[`form.input.ExpensesAssigned`]="{}">
        <CRUD
          :alwaysPostAll="true"
          @clicked:create="openForm"
          @form:load="initForm"
          :key="expensesAssignmentData.table.key"
          form-size="450"
          :fields="expensesAssignmentData.fields"
          :table="expensesAssignmentData.table"
          :api="{
            url: '/ccms/table/',
            params: {
              tableName: 'cnetd.projectadditionalexpense',
              projectId: $route.params.id
            }
          }"
        >
        </CRUD>
      </template>
    </CRUD>
  </v-container>
</template>

<script>
import CRUD from "@/components/util/CRUD/CRUD";

export default {
  components: {
    CRUD
  },
  data() {
    return {
      api: {
        url: "/ccms/table/",
        params: {
          tableName: "cnetd.project"
        }
      },
      table: {
        text: "Project Expense",
        key: "ProjectID",
        default: {
          itemsPerPage: 20,
          sortBy: ["UpdateDate"],
          sortDesc: [true]
        },
        components: {
          filter: true,
          delete: false,
          add: false,
          edit: false
        }
      },
      fields: [
        {
          text: "Project Name",
          name: "ProjectNM",
          searchable: true,
          input: {
            cols: 12
          }
        },
        {
          text: "Sales Manager",
          name: "BDMID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 1 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "PM",
          name: "PMID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 4 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Start Date",
          name: "PStartDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "End Date",
          name: "PEndDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Client",
          name: "ClientID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.clientmst",
            key: "ClientID",
            display: "ClientNM",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Status",
          name: "Status",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.projectstatus",
            key: "Status_Value",
            display: "Status_Text"
          }
        },
        {
          text: "Action",
          actions: [
            {
              text: "Assign Expenses",
              icon: "mdi-cash-multiple",
              method: "edit"
            }
          ]
        },
        {
          text: "Expenses Assigned",
          name: "ExpensesAssigned",
          listable: false,
          input: {
            cols: "12"
          }
        }
      ],
      expensesAssignmentData: {
        table: {
          text: "Expenses assigned",
          key: "id",
          default: {
            itemsPerPage: 50,
            sortBy: ["UpdateDate"],
            sortDesc: [true]
          },
          components: {
            filter: false
          }
        },
        fields: [
          {
            text: "Expense",
            name: "ExpenseID",
            input: {
              type: "L",
              validation: ["mandatory"],
              cols: "12"
            },
            foreign: {
              table: "cnetd.additionalexpensemst",
              key: "ExpenseID",
              display: "ExpenseType"
            }
          },
          {
            text: "Date",
            name: "Date",
            input: {
              type: "D",
              validation: ["mandatory"],
              cols: "12"
            }
          },
          {
            text: "Amount",
            name: "Amount",
            input: {
              validation: ["mandatory"],
              cols: "12"
            }
          }
        ]
      }
    };
  },
  methods: {
    openForm() {
      //this.$refs.paForm.open(this.$route.params.id);
    },
    initForm() {}
  }
};
</script>
